<template>
  <div class="mx-8">
    <v-subheader class="py-0 d-flex justify-space-between">
      <h3>DATA RKBMN</h3>

      <v-dialog v-model="dialogAdd" width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="success" v-bind="attrs" v-on="on"> Tambah Data </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 success white--text">
            Tambah Data
          </v-card-title>

          <br />

          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-select
                label="Tahun"
                v-model="dataAdd.tahunkebutuhan"
                :items="tahunOptions"
                :rules="requiredRules"
                required
              ></v-select>
              <v-textarea
                label="Keterangan"
                v-model="dataAdd.keterangan"
              ></v-textarea>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogAdd = false">
              Batal
            </v-btn>
            <v-btn color="primary" text @click="save()"> Simpan </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-subheader>

    <div class="mb-2"></div>

    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="filter"
          :items="filterOptions"
          outlined
          label="Tahun"
          hide-details
          @change="init()"
        ></v-select>
      </v-col>
    </v-row>

    <div class="mb-4"></div>

    <v-data-table
      :headers="headers"
      :items="listRKBMN.data"
      disable-sort
      hide-default-footer
    >
      <template v-slot:item.detail="{ item }">
        <v-btn class="primary" small @click="toDetailPage(item)">Lihat</v-btn>
      </template>

      <template v-slot:no-data>
        <label>Tidak ada data</label>
      </template>

      <template v-slot:footer>
        <v-divider class="mt-6"></v-divider>
        <v-row class="mt-3 mb-3" align="center" justify="end">
          <span class="caption">Limit:</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn dark text color="primary" class="ml-2" v-on="on">
                {{ limit }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in limitArray"
                :key="index"
                @click="updateLimit(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span class="caption ml-4 mr-4"
            >Total Data: {{ listRKBMN.jumlahdata }}</span
          >
          <span class="caption ml-4 mr-4"
            >Page {{ page }} of {{ listRKBMN.jumlahhalaman }}</span
          >
          <v-btn small color="primary" class="mr-4" @click="prevPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn small color="primary" class="mr-4" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" color="success" timeout="1000">
      {{ snackbarText }}

      <template v-slot:action>
        <v-btn fab x-small color="white" @click="snackbar = false">
          <v-icon color="error">mdi-close-thick</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: "",
      page: 1,
      limit: 12,
      limitArray: [12, 24, 48],
      headers: [
        { text: "No.", value: "nomor", width: "70px" },
        { text: "Tahun", value: "tahunkebutuhan" },
        { text: "Keterangan", value: "keterangan" },
        { text: "Detail", value: "detail" },
      ],
      dataAdd: {
        tahunkebutuhan: "",
        keterangan: "",
      },
      tahunOptions: [],
      dialogAdd: false,
      valid: true,
      requiredRules: [(v) => !!v || "Data harus diisi"],
      snackbar: false,
      snackbarText: "",
      filter: "ALL",
      filterOptions: [],
    };
  },
  watch: {
    dialogAdd() {
      if (!this.dialogAdd) {
        this.$refs.form.reset();
        this.dataAdd.tahunkebutuhan = "";
        this.dataAdd.keterangan = "";
      }
    },
  },
  computed: {
    listRKBMN() {
      return this.$store.getters.listRKBMN;
    },
  },
  created() {
    this.init();
    for (let i = 2025; i <= 2100; i++) {
      this.tahunOptions.push(i.toString());
    }

    for (let i = 2025; i <= 2100; i++) {
      this.filterOptions.push("ALL");
      this.filterOptions.push(i.toString());
    }
  },
  methods: {
    init() {
      if (this.filter == "ALL") {
        this.params = {
          page: this.page,
          limit: this.limit,
        };
      } else {
        this.params = {
          page: this.page,
          limit: this.limit,
          tahunKebutuhan: this.filter,
        };
      }

      this.$store.dispatch("getListRKBMN", this.params);
    },

    nextPage() {
      if (this.page + 1 <= this.listRKBMN.jumlahhalaman) {
        this.page += 1;
        this.init();
      }
    },

    prevPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.init();
      }
    },

    updateLimit(number) {
      this.limit = number;
      this.init();
    },

    save() {
      this.$refs.form.validate();
      if (this.dataAdd.tahunkebutuhan != "") {
        if (this.valid) {
          const data = {
            tahun: (parseInt(this.dataAdd.tahunkebutuhan) - 2).toString(),
            tahunkebutuhan: this.dataAdd.tahunkebutuhan,
            keterangan: this.dataAdd.keterangan,
          };
          this.$store.dispatch("addRKBMN", data).then((value) => {
            this.snackbar = true;
            this.snackbarText = value.pesan;
            if (value.hasil == "success") {
              this.init();
              this.dialogAdd = false;
            }
          });
        }
      } else {
        this.snackbar = true;
        this.snackbarText = "Tahun tidak boleh kosong";
      }
    },

    toDetailPage(item) {
      this.$router.push({
        name: "d_rkbmn",
        params: { id: item._id },
      });
    },
  },
};
</script>